
import axios from "axios";
import { mapState, mapGetters } from 'vuex'

export default {
  head: {
    title: 'Welcome'
  },

  auth: false,
  layout: 'landing',

  data () {
    return {
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      icons: [{ icon: 'mdi-link', link: '/species' }],
      items: [
        {
          title: 'Swollen Olive',
          description:
            `Dead Agaronia gibbosa form shallow marine sediments.
            They are carnivores.
            Individuals can grow to 48.5 mm.
            They have sexual reproduction.`,
          src: '/img/shells/seashell-wallpaper1-1080p.jpg',
        },
        {
          title: 'Blunt Tellin',
          description:
            `They are surficial modifiers.
            Individuals can grow to 60 mm.
            They have sexual reproduction.
            They are facultatively mobile animals. (eol.org)`,
          src: '/img/shells/seashell-wallpaper2.jpg',
        },
        {
          title: 'Dusky Cone',
          description:
            `Brown to white ground color.
            The last whorl's pattern varies from spiral banding in a blackish brown color to uniform white color.`,
          src: '/img/shells/seashell-wallpaper3-1080p.jpg',
        },
        {
          title: 'Elegant Goblet',
          description: 'Individuals can grow to 33.3 mm. (eol.org)',
          src: '/img/shells/seashell-wallpaper4-1080p.jpg',
        }
      ],
      // bivalveItems: [],
      // gastropodItems: [],
      // bivalveItems: [
      //   {
      //     title: 'Shell 1',
      //     text: `This is shell 1`,
      //     subtext: 'Description shell 1',
      //     img: '/img/sample-shells/shell1.jpg',
      //   },
      //   {
      //     title: 'Shell 2',
      //     text: `This is shell 2`,
      //     subtext: 'Description shell 2',
      //     img: '/img/sample-shells/shell2.jpg',
      //   },
      //   {
      //     title: 'Shell 3',
      //     text: `This is shell 3`,
      //     subtext: 'Description shell 3',
      //     img: '/img/sample-shells/shell3.jpg',
      //   },
      //   {
      //     title: 'Shell 4',
      //     text: `This is shell 4`,
      //     subtext: 'Description shell 4',
      //     img: '/img/sample-shells/shell4.jpg',
      //   },
      //   {
      //     title: 'Shell 5',
      //     text: `This is shell 5`,
      //     subtext: 'Description shell 5',
      //     img: '/img/sample-shells/shell5.jpg',
      //   },
      //   {
      //     title: 'Shell 6',
      //     text: `This is shell 6`,
      //     subtext: 'Description shell 6',
      //     img: '/img/sample-shells/shell6.jpg',
      //   },
      // ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },

  methods: {
    // async initialize () {
    //   this.getBivalveSpecies()
    //   this.getGastropodSpecies()
    // },

    async getBivalveSpecies(){
      await this.$store.dispatch('species/fetchBivalveSpecies',{ params: { species_class_id: 1, limit:5} })
    },


    async getGastropodSpecies(){
      await this.$store.dispatch('species/fetchGastropodSpecies',{ params: { species_class_id: 2, limit:5} })
    },

    speciesView(id){
      // window.location.href = '/#/species/' + id;
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      this.$router.push(`/species/${id}?${urlParams}`)
    },

    speciesList (id) {
      this.$router.push(`/species?shell_class=`+id);
    },
  },

  mounted(){
    this.getBivalveSpecies();
    this.getGastropodSpecies();
  },

  computed: {
    parallaxHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '850'
        case 'sm': return '900'
        case 'md': return '900'
        case 'lg': return '1050'
        case 'xl': return '1200'
      }
    },
    responsiveBorderRadius () {
      let data = this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 'bottom-rounded-sm' : 'bottom-rounded-lg'
      return data
    },
    ...mapGetters({
      bivalveItems: 'species/getBivalveSpecies',
      gastropodItems: 'species/getGastropodSpecies',
    })
  }
}
